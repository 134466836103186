import React, { Component } from 'react'
import { navigate } from 'gatsby'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import { FaTwitter, FaFacebook, FaGoogle } from 'react-icons/fa'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name='email'
          value={email}
          onChange={this.onChange}
          type='text'
          placeholder='Email Address'
        />
        <input
          name='password'
          value={password}
          onChange={this.onChange}
          type='password'
          placeholder='Password'
        />
        <button disabled={isInvalid} type='submit'>
          Sign In
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button type='submit'>
          <FaGoogle /> Sign In with Google
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button type='submit'>
          <FaFacebook /> Sign In with Facebook
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button type='submit'>
          <FaTwitter />
          Sign In with Twitter
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)

const SignInGoogle = withFirebase(SignInGoogleBase)

const SignInFacebook = withFirebase(SignInFacebookBase)

const SignInTwitter = withFirebase(SignInTwitterBase)

export default SignInForm

export { SignInGoogle, SignInFacebook, SignInTwitter }
