import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SignInForm, {
  SignInGoogle,
  SignInFacebook,
  SignInTwitter,
} from '../components/SignIn'
import { SignUpLink } from '../components/SignUp'
import { PasswordForgetLink } from '../components/PasswordForget'
import { FormPillow } from '../styles/globalStyles'

const SignInPage = () => (
  <Fragment>
    <h1>SignIn</h1>
    <FormPillow>
      <SignInForm />
      <br />
      <SignInGoogle />
      <br />
      <SignInFacebook />
      <br />
      <SignInTwitter />
      <br />
      <PasswordForgetLink />
      <SignUpLink />
    </FormPillow>
  </Fragment>
)

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
)
